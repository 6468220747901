import logo from './logo.svg';
import wip from './jajasch2000construction.gif';
import gif from './3dgifmaker45874.gif';
import './App.css';
import {DarkModeToggle} from "./DarkModeToggle";
import Clock from 'react-live-clock';

function App() {


    return (
        <div className="App">
            <header className="App-header">
                <DarkModeToggle />
                    <h1>
                        <Clock format={'HH:mm:ss'} ticking={true} timezone={'EU/Rome'} />
                    </h1>
                <img src={gif} className="App-logo" alt="logo"/>
                <p>
                    You entered the fantastic world of YouBorto. Enjoy your stay!
                </p>
                <center><img src={wip}/></center>
            </header>
            <h2>Cosa dicono di YouBorto</h2>

            <h3>Youborto: Il Regno dei Codici!</h3>


            <p>
                Immergiti in un mondo di creatività e innovazione con Youborto, il sito internet definitivo per gli
                amanti della programmazione! Questo straordinario portale online è un vero e proprio paradiso per
                chiunque abbia una passione per il coding e voglia scoprire nuove sfide, risolvere problemi e
                mettere in pratica le proprie idee.
            </p>

            <h3>
                **L'Orgoglio delle Categorie:**
            </h3>
            <p>
                Youborto brilla per la sua organizzazione impeccabile. Il suo vasto archivio di snippet di codice è
                diviso in categorie che si adattano perfettamente alle tue esigenze. Che tu sia un appassionato di
                Python, un guru di Java, o un esperto di HTML, c'è una categoria per te! Inoltre, la ricchezza di
                categorie ti permette di scoprire nuovi linguaggi e di espandere il tuo bagaglio di conoscenze.

            </p>

            <h3>
                **Snippets per Tutti i Gusti:**
            </h3>
            <p>
                Ogni categoria di Youborto è un vero e proprio scrigno d'oro di snippet di codice. Qui troverai
                soluzioni pronte all'uso per le tue sfide più complesse. Hai bisogno di un algoritmo di ordinamento
                efficiente? Cerchi un modo per gestire le eccezioni nel tuo codice? Youborto ha la risposta! Ogni
                snippet è accuratamente selezionato e commentato, per rendere la tua esperienza di apprendimento
                ancora più fluida e gratificante.

            </p>
            <h3>
                **Comunità Appassionata:**
            </h3>
            <p>
                Youborto non è solo un luogo per trovare codice; è anche una comunità di programmatori appassionati
                che condividono la loro conoscenza e collaborano per risolvere problemi. La sezione dei commenti ti
                permette di fare domande, suggerire miglioramenti e interagire con altri membri della community,
                creando così un ambiente di apprendimento collaborativo unico.

            </p>
            <h3>
                **Facile da Navigare:**
            </h3>
            <p>
                La facilità di navigazione di Youborto è semplicemente sbalorditiva. L'interfaccia intuitiva ti
                consente di trovare rapidamente ciò che cerchi. Grazie a un potente motore di ricerca e a un sistema
                di tag, puoi filtrare i risultati in base alle tue preferenze e ai tuoi interessi.
                <br/>


                Inoltre, Youborto è completamente gratuito! Non devi pagare un centesimo per accedere a questa fonte
                inesauribile di conoscenza e ispirazione. È un regalo alla comunità dei programmatori da parte di
                appassionati del coding che vogliono condividere la loro passione con il mondo.
                <br/>


                In sintesi, Youborto è molto più di un semplice sito internet. È una fucina di creatività, una
                risorsa inestimabile per gli sviluppatori e un luogo dove la programmazione diventa un'arte. Entra
                nell'universo di Youborto oggi stesso e lasciati ispirare dalla bellezza del codice!

            </p>
            <h3>
                Youborto: Un Sito Web Che la Spacca!
            </h3>
            <p>
                Guarda qua, ragazzi! Parliamo di Youborto, 'sto sito internet che spacca tutto! Sì, proprio così, è
                il posto dove la magia della programmazione prende vita!

            </p>
            <h3>
                **Le Categorie da Paura:**
            </h3>
            <p>
                Youborto è una miniera d'oro di snippet di codice, ma non è una roba mescolata alla rinfusa. No, no!
                Qui c'è una divisione spettacolare per categorie. Hai bisogno di Python? Loro te lo danno. Se sei
                più del tipo da Java, anche quello c'è! E poi HTML, JavaScript, quello che ti serve! Le categorie
                sono come le tessere di un puzzle, ognuna con la sua unicità.

            </p>
            <h3>**Snippet da Sogno:**</h3>
            <p>Dentro queste categorie, c'è una raccolta di snippet di codice che ti faranno venire voglia di fare
                il moonwalk di fronte al computer. Hanno tutto quello che serve: da algoritmi di ordinamento a
                gestione di eccezioni, c'è qualcosa per tutti. E cosa c'è di meglio? Ogni snippet è spiegato come si
                deve, quindi anche se sei alle prime armi, ti sentirai come un vero campione.
            </p>

            <h3>**Comunità da Campioni:**</h3>
            <p>Ma Youborto non è solo uno strumento, è una comunità di programmatori come noi! Puoi lasciare
                commenti, fare domande, condividere suggerimenti e entrare in contatto con gli altri appassionati. È
                un po' come una tavola rotonda della programmazione, solo che invece di spade si usano tastiere!
            </p>

            <h3>**Facile Come Bere un Caffè:**</h3>
            <p>La navigazione su Youborto è un gioco da ragazzi. Hanno fatto un lavoro fantastico con un'interfaccia
                semplice e intuitiva. Trovi subito quello che cerchi, grazie a un motore di ricerca potente e ad un
                sistema di tag. Sarai dentro e fuori in un attimo!


                E ora, ragazzi, il pezzo forte: Youborto è completamente gratis! Niente spese, niente abbonamenti. È
                un regalo per tutti noi appassionati di codice da altri appassionati di codice.


                Insomma, Youborto è un posto dove la programmazione diventa un'arte. È come un playground dove le
                idee prendono vita. Quindi, fate come me, ragazzi, e tuffatevi nel mondo affascinante di Youborto!
            </p>
            <footer>
                <i>©NyDev Version 0.0.5</i>
            </footer>
        </div>
    );
}

export default App;

import React from "react";
import Switch from "react-switch";
import { useColorScheme } from "./useColorScheme";
import "react-toggle/style.css"

export const DarkModeToggle = () => {
    const { isDark, setIsDark } = useColorScheme();

    return (
    <label>
        <span>chang* tem*</span>
        <Switch onChange={(check) => setIsDark(check)}
                checked={isDark}
                uncheckedIcon={
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 15,
                            color: "orange",
                            paddingRight: 2
                        }}
                    >
                        🔆
                    </div>
                }
                checkedIcon={
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 15,
                            color: "orange",
                            paddingRight: 2
                        }}
                    >
                        🌙
                    </div>
                }/>
    </label>
    );
};